/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from '../components/seo'

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
     markdownRemark( 
      id: { eq: $id }
    ) {
      id
      html
      tableOfContents(
        absolute: false
        maxDepth: 6
      )
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 80, srcSetBreakpoints: [350, 700, 1050, 1400]) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
const Post = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { 
    frontmatter, 
    html, 
    tableOfContents, 
    excerpt 
  } = markdownRemark
  const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""
  return (
    <Layout sx={blogStyles.blogContainer}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description ? frontmatter.description : excerpt}
        image={Image.src}
        article={true}
      />
      
      <article className="blog-post">
        <div sx={blogStyles.blogBody}>
          <div>
            <div sx={blogStyles.linksToContent} className="linksToContent">
              <div 
                dangerouslySetInnerHTML={{ __html: tableOfContents }} 
                sx={blogStyles.content}
              />
            </div>
          </div>
          <div sx={blogStyles.blogPostContent}>
            <h1>{frontmatter.title}</h1>
            <Img 
              fluid={Image} 
              alt={frontmatter.title + ' - Screenshot'}
              sx={blogStyles.latestImage}
            />
            <div
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Post


const blogStyles = {
  blogContainer: {
    variant: "variants.container",
    mx: 'inherit'
  },
  blogBody: {
    display: ["block", "block", "grid"],
    gridTemplateColumns: ["1fr", "1fr", "320px auto", "320px auto",],
    lineHeight: "1.6",
  },
  blogPostContent: {
    margin:"0 auto",
    px: [0, 0, 4],
    maxWidth: ["100%", "100%", "75ch"],
    color: "textColor",
    lineHeight: '160%',
    ".gatsby-image-wrapper": {
      mt: 5,
      "img": {
        border: "1px solid #aaa",
        borderRadius: "18px",
      },
    },
    "h1": {
      fontSize: 6
    },
    "h3, h4, h5, h6":{
      color: "secondary",
    },
    "h2": {
      mt: "7", 
      color: "#698b1f",
      ".anchor.before": {
        left: '-10px'
      }
    },
    "h3": {
      mt: "6", 
      mb: '2',
      "& + p": {
        mt: 2
      }
    },
    'hr': {
      my: 7,
      color: "#fff"
    },
    'blockquote': {
      borderLeft: "6px solid",
      borderColor: "#555",
      px: 3,
      mx: 0,
      "p": {
        fontSize: [3, 3, 4],
        fontWeight: '300'
      },
    },
    'blockquote, pre': {
      my: 5,
    },
    'figure': {
      mx: 0,
      my: 3,
      'figcaption': {
        mt: 1,
        fontSize: 0,
        textAlign: 'ceter',
        color: "#f2f2f2"
      },
    },
    'p, li': {
      fontSize: 3
    },
    'a': {
      color: 'primary',
      '&:hover': {
        color: '#517701',
      }
    }
  },
  linksToContent:{
    display: "block",
    position: "sticky",
    top: "0px",
    height: "calc(100vh)",
    overflow: "auto",
    pr: [0, 0, 3],
    pt: 4,
    borderRight: "2px solid #f2f2f2"
  },
  content:{
    "ul":{
      m: 0,
      p: 0,
      "& > li > a, & > li > p > a": {
        color: "navColor",
        display: 'block',
        fontSize: 3,
        fontWeight: 'bold',
        "&:hover": {
          color: "primary"
        }
      },
      "& > li > a": {
        mb: 3,
      },
      "& > li > p > a, & > li > p": {
        mb: 1
      },
      "& > li > ul a": {
        mb: 0,
      },
      "& > li p + ul":{
        listStyle: "none",
        pl: 0,
        mb: 3,
        "a":{
          color: "navColor",
          opacity: "0.7",
          fontSize: 2,
          fontWeight: 'normal',
          "&:hover": {
            opacity: "1",
            color: "primary"
          }
        },
      },
      "li p a": {
        m: 0,
        mb: 1
      }
    }
  }
}
